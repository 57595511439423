var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-preview"
  }, [_c('div', {
    staticClass: "DAV-preview__shop",
    class: _vm.setPreviewClass
  }, [_c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "border": "0"
    },
    attrs: {
      "id": "editor-preview",
      "src": _vm.url,
      "scrolling": "no"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }