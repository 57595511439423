<template>
  <div class="DAV-preview">
    <div class="DAV-preview__shop" :class="setPreviewClass">
      <iframe
        id="editor-preview"
        :src="url"
        style="width: 100%; height: 100%; border: 0"
        scrolling="no"
      />
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleEditorContainer = createNamespacedHelpers("ModuleEditorContainer");

export default {
  data() {
    return {};
  },
  watch: {
    // 연동 컴포넌트 선택
    getLinkedComponentId: {
      deep: true,
      handler: function (val) {
        setTimeout(() => {
          document.getElementById("editor-preview").contentWindow.postMessage(
            {
              action: "focus",
              id: val
            },
            `${window.location.origin}/preview`
          );
        }, 250);
      }
    },
    // 디바이스 타입
    getDeviceType() {
      document.getElementById("editor-preview").contentWindow.postMessage(
        {
          action: "device",
          device: this.getDeviceType
        },
        `${window.location.origin}/preview`
      );
    },
    // 이벤트 감지
    getEvent(val) {
      const container = this.getComponentContainer.get(
        this.getLinkedComponentId
      );
      if (val == "page") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "page",
            page: this.getEditableIndex
          },
          `${window.location.origin}/preview`
        );
        this.setEvent(null);
      }
      if (val === "layout") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "layout",
            componentLayout: this.getComponentLayout
          },
          `${window.location.origin}/preview`
        );
        this.setEvent("list");
      }
      if (val === "refresh") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "refresh",
            componentLayout: this.getComponentLayout,
            componentContainer: this.getComponentContainer
          },
          `${window.location.origin}/preview`
        );
        this.setEvent("list");
      }
      if (val === "init") {
        setTimeout(() => {
          document.getElementById("editor-preview").contentWindow.postMessage(
            {
              action: "init",
              id: "editor-preview",
              theme: this.getThemeLoadData,
              device: this.getDeviceType
            },
            `${window.location.origin}/preview`
          );
        }, 1000);
        this.setEvent(null);
      }
      if (val === "reset") {
        setTimeout(() => {
          document.getElementById("editor-preview").contentWindow.postMessage(
            {
              action: "reset",
              theme: this.getThemeLoadData
            },
            `${window.location.origin}/preview`
          );
        }, 1000);
        this.setEvent(null);
      }
      if (val === "move") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "move",
            componentLayout: this.getComponentLayout
          },
          `${window.location.origin}/preview`
        );
        this.setEvent(null);
      }
      if (val === "render") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "option",
            id: this.getLinkedComponentId,
            linkedOptions: container.linkedOptions
          },
          `${window.location.origin}/preview`
        );
        this.setEvent(null);
      }
      if (val === "style") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "margin",
            id: this.getLinkedComponentId,
            etc: container.etc
          },
          `${window.location.origin}/preview`
        );
        this.setEvent(null);
      }
      if (val === "display") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "display",
            id: this.getLinkedComponentId,
            visible: container.visible,
            displayOnPc: container.displayOnPc,
            displayOnMobile: container.displayOnMobile
          },
          `${window.location.origin}/preview`
        );
        this.setEvent(null);
      }
      if (val === "typefaces") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "typefaces",
            typefaces: this.getThemeFontLoadData.typefaces.css
          },
          `${window.location.origin}/preview`
        );
        this.setEvent(null);
      }
      if (val === "copy" || val === "add") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "add",
            componentLayout: this.getComponentLayout,
            componentContainer: this.getComponentContainer
          },
          `${window.location.origin}/preview`
        );
        this.setEvent(null);
      }
      if (val === "remove") {
        document.getElementById("editor-preview").contentWindow.postMessage(
          {
            action: "remove",
            ids: this.getDeleteItems,
            componentLayout: this.getComponentLayout,
            componentContainer: this.getComponentContainer
          },
          `${window.location.origin}/preview`
        );
        this.setEvent(null);
      }
    }
  },
  computed: {
    // class binding - 디바이스 타입
    setPreviewClass() {
      return {
        "DAV-preview__shop--mobile": this.getDeviceType === "mobile",
        "DAV-preview__shop--desktop": this.getDeviceType === "desktop"
      };
    },
    url() {
      return this.getMode === "partner"
        ? `/preview/${this.getThemeId}?mode=partner`
        : `/preview/${this.getThemeId}`;
    },

    ...ModuleTheme.mapGetters(["getThemeLoadData"]),
    ...ModuleEditor.mapGetters([
      "getDeleteItems",
      "getLinkedComponentId",
      "getDeviceType",
      "getThemeId",
      "getThemeFontLoadData",
      "getMode"
    ]),
    ...ModuleEditorContainer.mapGetters([
      "getEvent",
      "getComponentLayout",
      "getComponentContainer",
      "getEditableIndex"
    ])
  },
  mounted() {
    this.setEvent("init");
  },
  methods: {
    ...ModuleEditorContainer.mapMutations(["setEvent"])
  }
};
</script>

<style lang="scss" scoped>
.DAV-preview {
  position: fixed;
  top: 56px;
  right: 421px;
  bottom: 0;
  left: 0;
  background-color: #e7e9ec;

  &__shop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: #ffffff;
  }
  &__shop--desktop {
    margin-right: 16px;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-preview__shop--mobile {
    width: 421px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-preview {
    right: 0;
  }
  .DAV-preview__shop {
    margin-bottom: 56px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    &--desktop {
      margin-right: 0;
    }
  }
}
</style>
